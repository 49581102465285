export function addVoteEventListeners() {
    const voteButtons = document.querySelectorAll('.vote-button');

    voteButtons.forEach(button => {
        // Überprüfen Sie, ob der Button bereits einen Event-Listener hat
        if (!button.hasAttribute('data-vote-listener')) {
            button.addEventListener('click', handleVoteClick);
            button.setAttribute('data-vote-listener', 'true');
        }
    });
}
function handleVoteClick() {
        const memeId = this.dataset.memeId;
        const voteType = this.dataset.voteType;
        const url = `/memes/${memeId}/${voteType}`;
        const voteCount = this.parentElement.querySelector('.vote-count');
        const upvoteButton = this.parentElement.querySelector('[data-vote-type="upvote"]');
        const downvoteButton = this.parentElement.querySelector('[data-vote-type="downvote"]');

        axios.post(url)
            .then(response => {
                const data = response.data;
                voteCount.textContent = data.upvotes - data.downvotes;

                // Funktion zum Zurücksetzen eines Buttons
                const resetButton = (button) => {
                    button.classList.remove('bg-green-500', 'bg-red-500', 'text-white', 'dark:bg-green-700', 'dark:bg-red-700');
                    button.classList.add('text-gray-500', 'dark:text-gray-400', 'dark:hover:bg-gray-600', 'hover:bg-gray-200', 'dark:bg-gray-700');
                };

                // Funktion zum Aktivieren eines Buttons
                const activateButton = (button, isUpvote) => {
                    resetButton(button);
                    if (isUpvote) {
                        button.classList.add('bg-green-500', 'text-white', 'dark:bg-green-700', 'dark:hover:bg-green-600', 'hover:bg-green-200');
                    } else {
                        button.classList.add('bg-red-500', 'text-white', 'dark:bg-red-700', 'dark:hover:bg-red-600', 'hover:bg-red-200');
                    }
                };

                // Setze beide Buttons zurück
                resetButton(upvoteButton);
                resetButton(downvoteButton);

                // Aktiviere den entsprechenden Button, wenn ein Vote hinzugefügt wurde
                if (data.action === 'added') {
                    if (voteType === 'upvote') {
                        activateButton(upvoteButton, true);
                    } else {
                        activateButton(downvoteButton, false);
                    }
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    showToast('Du musst angemeldet sein, um dieses Meme bewerten zu können.');

                } else {
                    showToast('Ein Fehler ist aufgetreten. Bitte versuche es später erneut.', 'error');
                }
            });
}

